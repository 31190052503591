import {
  useLocalStorageState,
  useLockFn,
  useScroll,
  useThrottleEffect,
} from "ahooks";
import {
  CloseOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import useUrlState from "@ahooksjs/use-url-state";
import Image from "../../lib/Image";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import Dialog from "../../lib/Dialog";
import general from "../../general";
import $ from "jquery";
import Game from "./Game";

export default ({ setTitle, active }) => {
  const [query, setQuery] = useUrlState(
    { kw: "" },
    { navigateMode: "replace" }
  );
  const [history, setHistory] = useLocalStorageState("gift-search-history", {
    defaultValue: [],
  });
  useEffect(() => {
    setTitle(
      <>
        <div className="gift-search-input">
          <SearchOutlined className="icon" />
          <input
            type="text"
            value={query.kw}
            placeholder={"请输入游戏或礼包名称"}
            onChange={(e) => setQuery({ kw: e.target.value })}
            autoFocus
            onBlur={(e) => {
              let value = e.target.value;
              if (!value) {
                return;
              }
              _.pull(history, value);
              history.push(value);
              setHistory([...history]);
            }}
          />
          {query.kw ? (
            <CloseOutlined
              className="clear"
              onClick={() => setQuery({ kw: "" })}
            />
          ) : null}
          <div
            className="btn"
            onClick={() => {
              if (!query.kw) {
                return;
              }
              _.pull(history, query.kw);
              history.push(query.kw);
              setHistory([...history]);
            }}
          >
            搜索
          </div>
        </div>
      </>
    );
  }, [query.kw]);

  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      name: query.kw,
      pagination: { count: 20, page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/getGifts", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        setDatas((games) =>
          parms.page === 1 || !parms.page
            ? result.list
            : [...games, ...result.list]
        );
        setMore(result.paginated.more);
      })
      .catch((error) => Dialog.error(error.message));
  });
  const scroll = useScroll();
  useEffect(() => {
    if (active && query.kw) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [active, scroll, query.kw]);

  useThrottleEffect(
    () => {
      if (query.kw) {
        load({ page: 1 });
      }
    },
    [query.kw],
    { wait: 500 }
  );

  const [id, setId] = useState();

  return (
    <>
      <div className="gift-search">
        {query.kw ? (
          <>
            <div className="gift-items">
              {datas.map((gift) => (
                <div
                  key={gift.game_id}
                  className="gift-item"
                  onClick={() => setId(gift.game_id)}
                >
                  <div className="icon">
                    <Image src={gift.game_img} />
                  </div>
                  <div className="data">
                    <div className="name">{gift.game_name}</div>
                    <div className="value">
                      共<span>{gift.packs_count}</span>款礼包
                    </div>
                  </div>
                  <div className="opt">立即领取</div>
                </div>
              ))}
            </div>
            <Game id={id} setId={setId} />
          </>
        ) : (
          <>
            {history.length ? (
              <>
                <div className="history">
                  <div className="title">
                    <div className="text">搜索历史</div>
                    <div className="opt" onClick={() => setHistory([])}>
                      <DeleteOutlined className="icon" />
                      <div className="text">清除历史</div>
                    </div>
                  </div>
                  <div className="items">
                    {history.map((item, index) => (
                      <div key={index} className="item">
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
