import { Link, useHistory } from "react-router-dom";
import { NavLink } from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import { RightOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { sysUrl, url } from "../../config";
import useActiveState from "../../lib/useActiveState";

export default () => {
  const history = useHistory();
  const [user] = useActiveState("user");
  const [udid] = useActiveState("udid");

  let debug_param = '"设备信息"：' + '"' + window.navigator.userAgent;
  if (user.ip) {
    debug_param += '"' + "\n" + '"IP地址"：' + '"' + user.ip;
  }
  if (udid) {
    debug_param += '"' + "\n" + '"udid"：' + '"' + udid;
  }
  if (localStorage.getItem("username")) {
    debug_param +=
      '"' +
      "\n" +
      '"username"：' +
      '"' +
      localStorage.getItem("username") +
      '"';
  }

  const logout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    localStorage.removeItem("token");
    localStorage.setItem("logout", true);
    history.goBack();
  };

  return (
    <div className="user-setting">
      <NavLink className="item" to="/feedback">
        <div className="item-main">
          <div className="name">
            <img
              src="/resources/setting/feedback.png"
              alt=""
              style={{ width: "18px" }}
            />
            投诉反馈
          </div>
          <RightOutlined className="icon" />
        </div>
      </NavLink>

      <Link
        to={"/iframe?url=" + url + "/privacyPolicy&title=免责声明"}
        className="item"
      >
        <div className="item-main">
          <div className="name">
            <img
              src="/resources/setting/statement.png"
              alt=""
              style={{ width: "16px" }}
            />
            免责声明
          </div>
          <RightOutlined className="icon" />
        </div>
      </Link>

      <div className="item">
        <div className="item-main">
          <div className="name">
            <img
              src="/resources/setting/params.png"
              alt=""
              style={{ width: "21px" }}
            />
            调试参数
          </div>
          <CopyToClipboard
            text={debug_param}
            onCopy={() => Dialog.info("已复制")}
          >
            <div>点击复制</div>
          </CopyToClipboard>
        </div>
      </div>

      <NavLink
        className="item"
        to={`${sysUrl}/home/licenseMsg`}
        user={false}
        iframe={{ title: "证照信息" }}
      >
        <div className="item-main">
          <div className="name">
            <img
              src="/resources/setting/msg.png"
              alt=""
              style={{ width: "22px" }}
            />
            证照信息
          </div>
          <RightOutlined className="icon" />
        </div>
      </NavLink>

      {localStorage.getItem("token") && (
        <div className="user-btn" onClick={logout}>
          退出登录
        </div>
      )}
    </div>
  );
};
