import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import general from "../../general";
import { CloseOutlined } from "@ant-design/icons";
import Dialog from "../../lib/Dialog";
import useActiveState from "../../lib/useActiveState";

export default (props) => {
  const history = useHistory();
  const [user, setUser] = useActiveState("user");
  const [data, setData] = useState({});
  const [focus, setFocus] = useState("");
  const [saving, setSaving] = useState(false);
  const save = () => {
    // 实名认证
    if (
      localStorage.getItem("media_type") &&
      localStorage.getItem("gh_ivid") &&
      localStorage.getItem("gh_uid")
    ) {
      general.axios
        .post("/media/point", {
          media_type: localStorage.getItem("media_type"),
          gh_ivid: localStorage.getItem("gh_ivid"),
          uid: localStorage.getItem("gh_uid"),
          point_type: "realname",
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => console.log("media-point " + error));
    }

    if (saving) {
      return;
    }
    if (!data.name) {
      Dialog.error("请输入姓名");
      return;
    }
    if (!data.number) {
      Dialog.error("请输入身份证号");
      return;
    }
    setSaving(true);
    Dialog.loading();
    general.axios
      .post("/user/user/editMemberInfo", {
        real_name: data.name,
        identity_card: data.number,
      })
      .finally(() => {
        setSaving(false);
        Dialog.close();
      })
      .then((response) => {
        let { status } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setUser({
            ...user,
            ...{ real_name: data.name, identity_card: data.number },
          });
          Dialog.success("保存成功", () => history.goBack());
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };

  return (
    <div className="user-realname">
      <div className="user-input">
        <div className="name">姓名</div>
        <input
          name="name"
          type="text"
          value={data.name}
          placeholder="请输入姓名"
          autoComplete="off"
          onFocus={() => setFocus("name")}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        {focus === "name" && data.name ? (
          <CloseOutlined
            className="close"
            onClick={() => setData({ ...data, name: "" })}
          />
        ) : null}
      </div>
      <div className="user-input">
        <div className="name">身份证</div>
        <input
          name="number"
          type="text"
          value={data.number}
          placeholder="请输入身份证号"
          autoComplete="off"
          onFocus={() => setFocus("number")}
          onChange={(e) => setData({ ...data, number: e.target.value })}
        />
        {focus === "number" && data.number ? (
          <CloseOutlined
            className="close"
            onClick={() => setData({ ...data, number: "" })}
          />
        ) : null}
      </div>
      <div className="user-btn" onClick={save}>
        保存
      </div>
    </div>
  );
};
