import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { NavLink } from "../../lib/Navigation";
import general from "../../general";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default (props) => {
  const getGiftDetail = () => {
    general.axios
      .post("/base/gift/getGiftDetail", { gift_id: props.query.id })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        console.log("礼包详情-----", data);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        props.setTitle(data.gift_info.gift_name);
        setGift(data.gift_info);
      })
      .catch((err) => {
        Dialog.error("getGiftDetail " + err.message);
      });
  };

  useEffect(() => {
    getGiftDetail();
  }, []);

  const [gift, setGift] = useState({});
  const [giftCode, setGiftCode] = useState("");
  const giftReceive = () => {
    // 领取
    if (
      localStorage.getItem("media_type") &&
      localStorage.getItem("gh_ivid") &&
      localStorage.getItem("gh_uid")
    ) {
      general.axios
        .post("/media/point", {
          media_type: localStorage.getItem("media_type"),
          gh_ivid: localStorage.getItem("gh_ivid"),
          uid: localStorage.getItem("gh_uid"),
          point_type: "receive",
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => console.log("media-point " + error));
    }

    console.log(gift);
    // if (gift.unicode != 1 && gift.gift_surplus_num < 1) {
    //   return;
    // }
    Dialog.loading();
    general.axios
      .post("/base/game/getReceiveGift", { gift_id: gift.gift_id })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setGiftCode(data);
        }
      })
      .catch((err) => {
        Dialog.error("getReceiveGift " + err.message);
      });
  };

  return (
    <>
      <div className="gift-detail-container">
        <div className="detail-top">
          <div>
            <Image src={gift?.new_image?.thumb} />
          </div>
          <div className="top-right">
            <p className="gift-name">{gift.gift_name}</p>
            <p className="gift-time">
              有效期：
              {gift.gift_dealine
                ? dayjs.unix(gift.gift_dealine).format("YYYY-MM-DD")
                : ""}
            </p>
          </div>
        </div>

        <div className="detail-every">
          <p className="every-title">礼包说明</p>
          <p className="every-content">{gift.gift_desc}</p>
        </div>

        {gift.gift_introduce && (
          <div className="detail-every">
            <p className="every-title">使用说明</p>
            <p className="every-content">{gift.gift_introduce}</p>
          </div>
        )}

        <div className="detail-every">
          <p className="every-title">游戏说明</p>
          <p
            className="every-content"
            dangerouslySetInnerHTML={{
              __html: gift.game_desc
                ? gift.game_desc.replace(
                    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                    "$1<br>$2"
                  )
                : null,
            }}
          ></p>
        </div>

        <div className="gift-btn" onClick={() => giftReceive()}>
          <div className="btn">领取</div>
        </div>
      </div>

      {giftCode ? (
        <div className="dialog active">
          <div className="dialog-mask" />
          <div className="dialog-content">
            <div className="dialog-modal">
              <div className="modal-body">
                <div
                  className="modal-title"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p style={{ flex: "1 1", marginLeft: "16px" }}>领取成功</p>
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src="/resources/game/close.png"
                    alt=""
                    onClick={() => {
                      setGiftCode(null);
                    }}
                  />
                </div>
                <div
                  className="modal-content"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="gift-code">{giftCode}</div>
                  <div className="gift-copy">
                    <CopyToClipboard
                      text={giftCode}
                      onCopy={() => Dialog.info("已复制")}
                    >
                      <div onClick={() => setGiftCode(null)}>复制</div>
                    </CopyToClipboard>
                  </div>
                </div>

                {gift.gift_introduce ? (
                  <div className="gift-desc">
                    使用方法：{gift.gift_introduce}
                  </div>
                ) : (
                  <div className="gift-desc">请在游戏内使用~</div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
