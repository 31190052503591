import { useState } from "react";
import general from "../../general";
import { useMount, useToggle, useLocalStorageState } from "ahooks";
import {
  CloseOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { prod, url } from "../../config";
import { Link, useHistory } from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

export default (props) => {
  const history = useHistory();
  const { aplus_queue } = window;
  const [app] = useActiveState("app");
  useMount(() => {
    general.callback = props.query.callback || null;
    /*console.log('callback', general.callback);*/
    history.replace("/phonelogin");
  });
  const [datas, setDatas] = useLocalStorageState("users", {
    defaultValue: !prod
      ? [
          { id: 1, username: "test07", password: "test07" },
          { id: 2, username: "test08", password: "test08" },
          { id: 3, username: "apkceshi", password: "yy0011" },
          { id: 4, username: "iosceshi", password: "yy0011" },
          { id: 5, username: "17602909237", password: "123456" },
        ]
      : [],
  });
  const [focus, setFocus] = useState("");
  const [password, { toggle: togglePassword }] = useToggle(true);
  const [data, setData] = useState(datas.length > 0 ? { ...datas[0] } : {});
  const [agreement, setAgreement] = useLocalStorageState("agreement", {
    defaultValue: true,
  });
  const [loging, setLoging] = useState(false);
  const [config, setConfig] = useActiveState("config");

  let timer = null;
  const login = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      loginCode();
    }, 500);
  };

  const loginCode = () => {
    if (!data.username) {
      Dialog.error("请输入用户名");
      return;
    }
    if (!data.password) {
      Dialog.error("请输入密码");
      return;
    }
    if (!agreement) {
      Dialog.error("请阅读下方的协议");
      return;
    }
    if (loging) {
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios
      .post("/user/user/login", data)
      .finally(() => {
        setLoging(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data: result } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          localStorage.setItem("user_id", result.user_id);
          localStorage.setItem("username", data.username);
          localStorage.setItem("password", data.password);
          localStorage.setItem("token", result.token);
          let tmp = datas.filter((item) => item.username !== data.username);
          tmp.unshift(data);
          setDatas(tmp);
          aplus_queue.push({
            action: "aplus.record",
            arguments: [
              "login",
              "login",
              {
                agent: localStorage.getItem("agent"),
                username: data.username,
              },
            ],
          });

          // 登录
          if (
            localStorage.getItem("media_type") &&
            localStorage.getItem("gh_ivid") &&
            localStorage.getItem("gh_uid")
          ) {
            // 游戏用户与ocpc做关联
            general.axios
              .post("/media/bind", {
                media_type: localStorage.getItem("media_type"),
                gh_ivid: localStorage.getItem("gh_ivid"),
                uid: localStorage.getItem("gh_uid"),
                username: localStorage.getItem("username"),
              })
              .then((res) => {
                console.log(res);

                general.axios
                  .post("/media/point", {
                    media_type: localStorage.getItem("media_type"),
                    gh_ivid: localStorage.getItem("gh_ivid"),
                    uid: localStorage.getItem("gh_uid"),
                    point_type: "login_box",
                  })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((error) => console.log("media-point " + error));
              })
              .catch((error) => console.log("media-bind " + error));
          }

          Dialog.info("登录成功", () => general.goBack(true));
        }
      })
      .catch((err) => {
        if (err) {
          Dialog.error("login " + err.message);
        } else {
          Dialog.error("登录失败，请稍后重试");
        }
      });
  };

  return (
    <div className="account-login">
      <div className="body">
        <div className="logo">
          <img src={app.applogo} alt="" />
        </div>
        <div className="input-item">
          <div className="label">用户名</div>
          <div className="input">
            <input
              name="username"
              type="text"
              value={data.username}
              placeholder="请输入用户名"
              autoComplete="off"
              onFocus={(e) => setFocus("username")}
              onChange={(e) => setData({ ...data, username: e.target.value })}
            />
            {focus == "username" && data.username ? (
              <CloseOutlined
                className="assist close"
                onClick={() => setData({ ...data, username: "" })}
              />
            ) : null}
            <div className="assist select">
              <DownOutlined className="select" />
              <select
                onChange={(e) => {
                  console.log(data);
                  setData(
                    e.target.value != ""
                      ? datas[parseInt(e.target.value)]
                      : { username: "", password: "" }
                  );
                }}
                defaultValue={datas.findIndex((value) => {
                  return value.username == data.username;
                })}
              >
                <option value="">手动输入</option>
                {datas.map((item, index) => (
                  <option key={index} value={index}>
                    {item.username}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="input-item">
          <div className="label">密码</div>
          <div className="input">
            <input
              name="password"
              type={password ? "password" : "text"}
              value={data.password}
              placeholder="请输入密码"
              onFocus={(e) => setFocus("password")}
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
            {focus == "password" && data.password ? (
              <CloseOutlined
                className="assist close"
                onClick={() => setData((data) => ({ ...data, password: "" }))}
              />
            ) : null}
            <div className="assist password" onClick={() => togglePassword()}>
              {password ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </div>
          </div>
        </div>
        <div className="operate clearfix">
          {config.is_quick_register ? (
            <>
              <Link to="/forget" className="item">
                忘记密码？
              </Link>
              <Link to="/register" className="item">
                快速注册
              </Link>
            </>
          ) : (
            <Link to="/forget">忘记密码？</Link>
          )}
        </div>
        <div className={"btn " + (loging ? "disable" : "")} onClick={login}>
          登录
        </div>
        <Link to="/phonelogin" className="btn-phone">
          手机号登录&gt;
        </Link>
        {/*<div className="btn-phone" onClick={() => general.goBack(true)}>返回></div>*/}
      </div>
      <div className="account-agreement">
        <label>
          <span
            className={"agreement" + (agreement ? " checked" : "")}
            onClick={() => setAgreement(true)}
          />
          登录即代表您同意
          <Link to={"/iframe?url=" + url + "/userAgreement&title=用户协议"}>
            用户协议
          </Link>
          、
          <Link to={"/iframe?url=" + url + "/privacyPolicy&title=隐私政策"}>
            隐私政策
          </Link>
          <br />
          接受免除或者限制责任、诉讼管辖约定等粗体标示条款
        </label>
      </div>
    </div>
  );
};
